import React from "react"

const StreetMap = () => (
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d727.6829564895608!2d172.72901578618706!3d-43.15216037602381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDPCsDA5JzA3LjciUyAxNzLCsDQzJzQ1LjgiRQ!5e0!3m2!1sen!2snz!4v1599947323933!5m2!1sen!2snz"
          title="Market Gogole Map"
          className="gmap"
          allow="fullscreen"
          aria-hidden="false"/>
)
export default StreetMap
